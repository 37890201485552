/* You can add global styles to this file, and also import other style files */
.app-form-multiple-input-row .form-group {
  margin-bottom: 1rem !important;
}

.no-data-available {
  text-align: center;
}

.dataTables_empty {
  display: none;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.input-lg .select2-selection--single {
  height: calc(4.375rem + 1px) !important;
  padding: 9px 12px;
  font-size: 1.25rem;
  line-height: 1.5;
}
.m-content--skin-light2 .m-body {
  background-color: #fff !important;
}

@media (min-width: 1025px) {
  body.m-aside-left--skin-dark .m-header .m-header-head {
    -webkit-box-shadow: 0px 2px 11px 1px rgb(69 65 78 / 23%) !important;
    -moz-box-shadow: 0px 2px 11px 1px rgb(69 65 78 / 23%) !important;
    box-shadow: 0px 2px 11px 1px rgb(69 65 78 / 23%) !important;
  }
}


.bg-primary {
  background-color: #007bff !important
}

.bg-primary, .bg-primary > a {
  color: #fff !important
}

.bg-primary.btn:hover {
  border-color: #0062cc;
  color: #ececec
}

.bg-primary.btn.active, .bg-primary.btn:active, .bg-primary.btn:not(:disabled):not(.disabled).active, .bg-primary.btn:not(:disabled):not(.disabled):active {
  background-color: #0062cc !important;
  border-color: #005cbf;
  color: #fff
}

.bg-secondary {
  background-color: #6c757d !important
}

.bg-secondary, .bg-secondary > a {
  color: #fff !important
}

.bg-secondary.btn:hover {
  border-color: #545b62;
  color: #ececec
}

.bg-secondary.btn.active, .bg-secondary.btn:active, .bg-secondary.btn:not(:disabled):not(.disabled).active, .bg-secondary.btn:not(:disabled):not(.disabled):active {
  background-color: #545b62 !important;
  border-color: #4e555b;
  color: #fff
}

.bg-success {
  background-color: #28a745 !important
}

.bg-success, .bg-success > a {
  color: #fff !important
}

.bg-success.btn:hover {
  border-color: #1e7e34;
  color: #ececec
}

.bg-success.btn.active, .bg-success.btn:active, .bg-success.btn:not(:disabled):not(.disabled).active, .bg-success.btn:not(:disabled):not(.disabled):active {
  background-color: #1e7e34 !important;
  border-color: #1c7430;
  color: #fff
}

.bg-info {
  background-color: #17a2b8 !important
}

.bg-info, .bg-info > a {
  color: #fff !important
}

.bg-info.btn:hover {
  border-color: #117a8b;
  color: #ececec
}

.bg-info.btn.active, .bg-info.btn:active, .bg-info.btn:not(:disabled):not(.disabled).active, .bg-info.btn:not(:disabled):not(.disabled):active {
  background-color: #117a8b !important;
  border-color: #10707f;
  color: #fff
}

.bg-warning {
  background-color: #ffc107 !important
}

.bg-warning, .bg-warning > a {
  color: #1f2d3d !important
}

.bg-warning.btn:hover {
  border-color: #d39e00;
  color: #121a24
}

.bg-warning.btn.active, .bg-warning.btn:active, .bg-warning.btn:not(:disabled):not(.disabled).active, .bg-warning.btn:not(:disabled):not(.disabled):active {
  background-color: #d39e00 !important;
  border-color: #c69500;
  color: #1f2d3d
}

.bg-danger {
  background-color: #dc3545 !important
}

.bg-danger, .bg-danger > a {
  color: #fff !important
}

.bg-danger.btn:hover {
  border-color: #bd2130;
  color: #ececec
}

.bg-danger.btn.active, .bg-danger.btn:active, .bg-danger.btn:not(:disabled):not(.disabled).active, .bg-danger.btn:not(:disabled):not(.disabled):active {
  background-color: #bd2130 !important;
  border-color: #b21f2d;
  color: #fff
}

.bg-light {
  background-color: #f8f9fa !important
}

.bg-light, .bg-light > a {
  color: #1f2d3d !important
}

.bg-light.btn:hover {
  border-color: #dae0e5;
  color: #121a24
}

.bg-light.btn.active, .bg-light.btn:active, .bg-light.btn:not(:disabled):not(.disabled).active, .bg-light.btn:not(:disabled):not(.disabled):active {
  background-color: #dae0e5 !important;
  border-color: #d3d9df;
  color: #1f2d3d
}

.bg-dark {
  background-color: #343a40 !important
}

.bg-dark, .bg-dark > a {
  color: #fff !important
}

.bg-dark.btn:hover {
  border-color: #1d2124;
  color: #ececec
}

.bg-dark.btn.active, .bg-dark.btn:active, .bg-dark.btn:not(:disabled):not(.disabled).active, .bg-dark.btn:not(:disabled):not(.disabled):active {
  background-color: #1d2124 !important;
  border-color: #171a1d;
  color: #fff
}

.bg-navy {
  background-color: #001f3f !important
}

.bg-navy, .bg-navy > a {
  color: #fff !important
}

.bg-navy.btn:hover {
  border-color: #00060c;
  color: #ececec
}

.bg-navy.btn.active, .bg-navy.btn:active, .bg-navy.btn:not(:disabled):not(.disabled).active, .bg-navy.btn:not(:disabled):not(.disabled):active {
  background-color: #00060c !important;
  border-color: #000;
  color: #fff
}

.bg-olive {
  background-color: #3d9970 !important
}

.bg-olive, .bg-olive > a {
  color: #fff !important
}

.bg-olive.btn:hover {
  border-color: #2e7555;
  color: #ececec
}

.bg-olive.btn.active, .bg-olive.btn:active, .bg-olive.btn:not(:disabled):not(.disabled).active, .bg-olive.btn:not(:disabled):not(.disabled):active {
  background-color: #2e7555 !important;
  border-color: #2b6b4f;
  color: #fff
}

.bg-lime {
  background-color: #01ff70 !important
}

.bg-lime, .bg-lime > a {
  color: #1f2d3d !important
}

.bg-lime.btn:hover {
  border-color: #00cd5a;
  color: #121a24
}

.bg-lime.btn.active, .bg-lime.btn:active, .bg-lime.btn:not(:disabled):not(.disabled).active, .bg-lime.btn:not(:disabled):not(.disabled):active {
  background-color: #00cd5a !important;
  border-color: #00c054;
  color: #fff
}

.bg-fuchsia {
  background-color: #f012be !important
}

.bg-fuchsia, .bg-fuchsia > a {
  color: #fff !important
}

.bg-fuchsia.btn:hover {
  border-color: #c30c9a;
  color: #ececec
}

.bg-fuchsia.btn.active, .bg-fuchsia.btn:active, .bg-fuchsia.btn:not(:disabled):not(.disabled).active, .bg-fuchsia.btn:not(:disabled):not(.disabled):active {
  background-color: #c30c9a !important;
  border-color: #b70c90;
  color: #fff
}

.bg-maroon {
  background-color: #d81b60 !important
}

.bg-maroon, .bg-maroon > a {
  color: #fff !important
}

.bg-maroon.btn:hover {
  border-color: #ab154c;
  color: #ececec
}

.bg-maroon.btn.active, .bg-maroon.btn:active, .bg-maroon.btn:not(:disabled):not(.disabled).active, .bg-maroon.btn:not(:disabled):not(.disabled):active {
  background-color: #ab154c !important;
  border-color: #9f1447;
  color: #fff
}

.bg-blue {
  background-color: #007bff !important
}

.bg-blue, .bg-blue > a {
  color: #fff !important
}

.bg-blue.btn:hover {
  border-color: #0062cc;
  color: #ececec
}

.bg-blue.btn.active, .bg-blue.btn:active, .bg-blue.btn:not(:disabled):not(.disabled).active, .bg-blue.btn:not(:disabled):not(.disabled):active {
  background-color: #0062cc !important;
  border-color: #005cbf;
  color: #fff
}

.bg-indigo {
  background-color: #6610f2 !important
}

.bg-indigo, .bg-indigo > a {
  color: #fff !important
}

.bg-indigo.btn:hover {
  border-color: #510bc4;
  color: #ececec
}

.bg-indigo.btn.active, .bg-indigo.btn:active, .bg-indigo.btn:not(:disabled):not(.disabled).active, .bg-indigo.btn:not(:disabled):not(.disabled):active {
  background-color: #510bc4 !important;
  border-color: #4c0ab8;
  color: #fff
}

.bg-purple {
  background-color: #6f42c1 !important
}

.bg-purple, .bg-purple > a {
  color: #fff !important
}

.bg-purple.btn:hover {
  border-color: #59339d;
  color: #ececec
}

.bg-purple.btn.active, .bg-purple.btn:active, .bg-purple.btn:not(:disabled):not(.disabled).active, .bg-purple.btn:not(:disabled):not(.disabled):active {
  background-color: #59339d !important;
  border-color: #533093;
  color: #fff
}

.bg-pink {
  background-color: #e83e8c !important
}

.bg-pink, .bg-pink > a {
  color: #fff !important
}

.bg-pink.btn:hover {
  border-color: #d91a72;
  color: #ececec
}

.bg-pink.btn.active, .bg-pink.btn:active, .bg-pink.btn:not(:disabled):not(.disabled).active, .bg-pink.btn:not(:disabled):not(.disabled):active {
  background-color: #d91a72 !important;
  border-color: #ce196c;
  color: #fff
}

.bg-red {
  background-color: #dc3545 !important
}

.bg-red, .bg-red > a {
  color: #fff !important
}

.bg-red.btn:hover {
  border-color: #bd2130;
  color: #ececec
}

.bg-red.btn.active, .bg-red.btn:active, .bg-red.btn:not(:disabled):not(.disabled).active, .bg-red.btn:not(:disabled):not(.disabled):active {
  background-color: #bd2130 !important;
  border-color: #b21f2d;
  color: #fff
}

.bg-orange {
  background-color: #fd7e14 !important
}

.bg-orange, .bg-orange > a {
  color: #1f2d3d !important
}

.bg-orange.btn:hover {
  border-color: #dc6502;
  color: #121a24
}

.bg-orange.btn.active, .bg-orange.btn:active, .bg-orange.btn:not(:disabled):not(.disabled).active, .bg-orange.btn:not(:disabled):not(.disabled):active {
  background-color: #dc6502 !important;
  border-color: #cf5f02;
  color: #fff
}

.bg-yellow {
  background-color: #ffc107 !important
}

.bg-yellow, .bg-yellow > a {
  color: #1f2d3d !important
}

.bg-yellow.btn:hover {
  border-color: #d39e00;
  color: #121a24
}

.bg-yellow.btn.active, .bg-yellow.btn:active, .bg-yellow.btn:not(:disabled):not(.disabled).active, .bg-yellow.btn:not(:disabled):not(.disabled):active {
  background-color: #d39e00 !important;
  border-color: #c69500;
  color: #1f2d3d
}

.bg-green {
  background-color: #28a745 !important
}

.bg-green, .bg-green > a {
  color: #fff !important
}

.bg-green.btn:hover {
  border-color: #1e7e34;
  color: #ececec
}

.bg-green.btn.active, .bg-green.btn:active, .bg-green.btn:not(:disabled):not(.disabled).active, .bg-green.btn:not(:disabled):not(.disabled):active {
  background-color: #1e7e34 !important;
  border-color: #1c7430;
  color: #fff
}

.bg-teal {
  background-color: #20c997 !important
}

.bg-teal, .bg-teal > a {
  color: #fff !important
}

.bg-teal.btn:hover {
  border-color: #199d76;
  color: #ececec
}

.bg-teal.btn.active, .bg-teal.btn:active, .bg-teal.btn:not(:disabled):not(.disabled).active, .bg-teal.btn:not(:disabled):not(.disabled):active {
  background-color: #199d76 !important;
  border-color: #17926e;
  color: #fff
}

.bg-cyan {
  background-color: #17a2b8 !important
}

.bg-cyan, .bg-cyan > a {
  color: #fff !important
}

.bg-cyan.btn:hover {
  border-color: #117a8b;
  color: #ececec
}

.bg-cyan.btn.active, .bg-cyan.btn:active, .bg-cyan.btn:not(:disabled):not(.disabled).active, .bg-cyan.btn:not(:disabled):not(.disabled):active {
  background-color: #117a8b !important;
  border-color: #10707f;
  color: #fff
}

.bg-white {
  background-color: #fff !important
}

.bg-white, .bg-white > a {
  color: #1f2d3d !important
}

.bg-white.btn:hover {
  border-color: #e6e6e6;
  color: #121a24
}

.bg-white.btn.active, .bg-white.btn:active, .bg-white.btn:not(:disabled):not(.disabled).active, .bg-white.btn:not(:disabled):not(.disabled):active {
  background-color: #e6e6e6 !important;
  border-color: #dfdfdf;
  color: #1f2d3d
}

.bg-gray {
  background-color: #6c757d !important
}

.bg-gray, .bg-gray > a {
  color: #fff !important
}

.bg-gray.btn:hover {
  border-color: #545b62;
  color: #ececec
}

.bg-gray.btn.active, .bg-gray.btn:active, .bg-gray.btn:not(:disabled):not(.disabled).active, .bg-gray.btn:not(:disabled):not(.disabled):active {
  background-color: #545b62 !important;
  border-color: #4e555b;
  color: #fff
}

.bg-gray-dark {
  background-color: #343a40 !important
}

.bg-gray-dark, .bg-gray-dark > a {
  color: #fff !important
}

.bg-gray-dark.btn:hover {
  border-color: #1d2124;
  color: #ececec
}

.bg-gray-dark.btn.active, .bg-gray-dark.btn:active, .bg-gray-dark.btn:not(:disabled):not(.disabled).active, .bg-gray-dark.btn:not(:disabled):not(.disabled):active {
  background-color: #1d2124 !important;
  border-color: #171a1d;
  color: #fff
}

//.bg-gray {
//  background-color: #adb5bd;
//  color: #1f2d3d
//}

.bg-gray-light {
  background-color: #f2f4f5;
  color: #1f2d3d !important
}
.bg-gray-light-2 {
  background-color: #adb5bd;
  color: #1f2d3d !important
}

.bg-black {
  background-color: #000;
  color: #fff !important
}

.bg-white {
  background-color: #fff;
  color: #1f2d3d !important
}

.bg-gradient-primary {
  color: #fff
}

.bg-gradient-primary {
  background: #007bff linear-gradient(180deg, #268fff, #007bff) repeat-x !important
}

.bg-gradient-primary.btn.disabled, .bg-gradient-primary.btn:disabled, .bg-gradient-primary.btn:not(:disabled):not(.disabled).active, .bg-gradient-primary.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-primary.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-primary.btn:hover {
  border-color: #0062cc;
  color: #ececec
}

.bg-gradient-primary.btn:hover {
  background: #0069d9 linear-gradient(180deg, #267fde, #0069d9) repeat-x !important
}

.bg-gradient-primary.btn.active, .bg-gradient-primary.btn:active, .bg-gradient-primary.btn:not(:disabled):not(.disabled).active, .bg-gradient-primary.btn:not(:disabled):not(.disabled):active {
  border-color: #005cbf;
  color: #fff
}

.bg-gradient-primary.btn.active, .bg-gradient-primary.btn:active, .bg-gradient-primary.btn:not(:disabled):not(.disabled).active, .bg-gradient-primary.btn:not(:disabled):not(.disabled):active {
  background: #0062cc linear-gradient(180deg, #267ad4, #0062cc) repeat-x !important
}

.bg-gradient-secondary {
  color: #fff
}

.bg-gradient-secondary {
  background: #6c757d linear-gradient(180deg, #828a91, #6c757d) repeat-x !important
}

.bg-gradient-secondary.btn.disabled, .bg-gradient-secondary.btn:disabled, .bg-gradient-secondary.btn:not(:disabled):not(.disabled).active, .bg-gradient-secondary.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-secondary.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-secondary.btn:hover {
  border-color: #545b62;
  color: #ececec
}

.bg-gradient-secondary.btn:hover {
  background: #5a6268 linear-gradient(180deg, #73797f, #5a6268) repeat-x !important
}

.bg-gradient-secondary.btn.active, .bg-gradient-secondary.btn:active, .bg-gradient-secondary.btn:not(:disabled):not(.disabled).active, .bg-gradient-secondary.btn:not(:disabled):not(.disabled):active {
  border-color: #4e555b;
  color: #fff
}

.bg-gradient-secondary.btn.active, .bg-gradient-secondary.btn:active, .bg-gradient-secondary.btn:not(:disabled):not(.disabled).active, .bg-gradient-secondary.btn:not(:disabled):not(.disabled):active {
  background: #545b62 linear-gradient(180deg, #6e7479, #545b62) repeat-x !important
}

.bg-gradient-success {
  color: #fff
}

.bg-gradient-success {
  background: #28a745 linear-gradient(180deg, #48b461, #28a745) repeat-x !important
}

.bg-gradient-success.btn.disabled, .bg-gradient-success.btn:disabled, .bg-gradient-success.btn:not(:disabled):not(.disabled).active, .bg-gradient-success.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-success.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-success.btn:hover {
  border-color: #1e7e34;
  color: #ececec
}

.bg-gradient-success.btn:hover {
  background: #218838 linear-gradient(180deg, #429a56, #218838) repeat-x !important
}

.bg-gradient-success.btn.active, .bg-gradient-success.btn:active, .bg-gradient-success.btn:not(:disabled):not(.disabled).active, .bg-gradient-success.btn:not(:disabled):not(.disabled):active {
  border-color: #1c7430;
  color: #fff
}

.bg-gradient-success.btn.active, .bg-gradient-success.btn:active, .bg-gradient-success.btn:not(:disabled):not(.disabled).active, .bg-gradient-success.btn:not(:disabled):not(.disabled):active {
  background: #1e7e34 linear-gradient(180deg, #409152, #1e7e34) repeat-x !important
}

.bg-gradient-info {
  color: #fff
}

.bg-gradient-info {
  background: #17a2b8 linear-gradient(180deg, #3ab0c3, #17a2b8) repeat-x !important
}

.bg-gradient-info.btn.disabled, .bg-gradient-info.btn:disabled, .bg-gradient-info.btn:not(:disabled):not(.disabled).active, .bg-gradient-info.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-info.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-info.btn:hover {
  border-color: #117a8b;
  color: #ececec
}

.bg-gradient-info.btn:hover {
  background: #138496 linear-gradient(180deg, #3697a6, #138496) repeat-x !important
}

.bg-gradient-info.btn.active, .bg-gradient-info.btn:active, .bg-gradient-info.btn:not(:disabled):not(.disabled).active, .bg-gradient-info.btn:not(:disabled):not(.disabled):active {
  border-color: #10707f;
  color: #fff
}

.bg-gradient-info.btn.active, .bg-gradient-info.btn:active, .bg-gradient-info.btn:not(:disabled):not(.disabled).active, .bg-gradient-info.btn:not(:disabled):not(.disabled):active {
  background: #117a8b linear-gradient(180deg, #358e9c, #117a8b) repeat-x !important
}

.bg-gradient-warning {
  color: #1f2d3d
}

.bg-gradient-warning {
  background: #ffc107 linear-gradient(180deg, #ffca2c, #ffc107) repeat-x !important
}

.bg-gradient-warning.btn.disabled, .bg-gradient-warning.btn:disabled, .bg-gradient-warning.btn:not(:disabled):not(.disabled).active, .bg-gradient-warning.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-warning.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-warning.btn:hover {
  border-color: #d39e00;
  color: #121a24
}

.bg-gradient-warning.btn:hover {
  background: #e0a800 linear-gradient(180deg, #e4b526, #e0a800) repeat-x !important
}

.bg-gradient-warning.btn.active, .bg-gradient-warning.btn:active, .bg-gradient-warning.btn:not(:disabled):not(.disabled).active, .bg-gradient-warning.btn:not(:disabled):not(.disabled):active {
  border-color: #c69500;
  color: #1f2d3d
}

.bg-gradient-warning.btn.active, .bg-gradient-warning.btn:active, .bg-gradient-warning.btn:not(:disabled):not(.disabled).active, .bg-gradient-warning.btn:not(:disabled):not(.disabled):active {
  background: #d39e00 linear-gradient(180deg, #daad26, #d39e00) repeat-x !important
}

.bg-gradient-danger {
  color: #fff
}

.bg-gradient-danger {
  background: #dc3545 linear-gradient(180deg, #e15361, #dc3545) repeat-x !important
}

.bg-gradient-danger.btn.disabled, .bg-gradient-danger.btn:disabled, .bg-gradient-danger.btn:not(:disabled):not(.disabled).active, .bg-gradient-danger.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-danger.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-danger.btn:hover {
  border-color: #bd2130;
  color: #ececec
}

.bg-gradient-danger.btn:hover {
  background: #c82333 linear-gradient(180deg, #d04451, #c82333) repeat-x !important
}

.bg-gradient-danger.btn.active, .bg-gradient-danger.btn:active, .bg-gradient-danger.btn:not(:disabled):not(.disabled).active, .bg-gradient-danger.btn:not(:disabled):not(.disabled):active {
  border-color: #b21f2d;
  color: #fff
}

.bg-gradient-danger.btn.active, .bg-gradient-danger.btn:active, .bg-gradient-danger.btn:not(:disabled):not(.disabled).active, .bg-gradient-danger.btn:not(:disabled):not(.disabled):active {
  background: #bd2130 linear-gradient(180deg, #c7424f, #bd2130) repeat-x !important
}

.bg-gradient-light {
  color: #1f2d3d
}

.bg-gradient-light {
  background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x !important
}

.bg-gradient-light.btn.disabled, .bg-gradient-light.btn:disabled, .bg-gradient-light.btn:not(:disabled):not(.disabled).active, .bg-gradient-light.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-light.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-light.btn:hover {
  border-color: #dae0e5;
  color: #121a24
}

.bg-gradient-light.btn:hover {
  background: #e2e6ea linear-gradient(180deg, #e6eaed, #e2e6ea) repeat-x !important
}

.bg-gradient-light.btn.active, .bg-gradient-light.btn:active, .bg-gradient-light.btn:not(:disabled):not(.disabled).active, .bg-gradient-light.btn:not(:disabled):not(.disabled):active {
  border-color: #d3d9df;
  color: #1f2d3d
}

.bg-gradient-light.btn.active, .bg-gradient-light.btn:active, .bg-gradient-light.btn:not(:disabled):not(.disabled).active, .bg-gradient-light.btn:not(:disabled):not(.disabled):active {
  background: #dae0e5 linear-gradient(180deg, #e0e4e9, #dae0e5) repeat-x !important
}

.bg-gradient-dark {
  color: #fff
}

.bg-gradient-dark {
  background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x !important
}

.bg-gradient-dark.btn.disabled, .bg-gradient-dark.btn:disabled, .bg-gradient-dark.btn:not(:disabled):not(.disabled).active, .bg-gradient-dark.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-dark.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-dark.btn:hover {
  border-color: #1d2124;
  color: #ececec
}

.bg-gradient-dark.btn:hover {
  background: #23272b linear-gradient(180deg, #44474b, #23272b) repeat-x !important
}

.bg-gradient-dark.btn.active, .bg-gradient-dark.btn:active, .bg-gradient-dark.btn:not(:disabled):not(.disabled).active, .bg-gradient-dark.btn:not(:disabled):not(.disabled):active {
  border-color: #171a1d;
  color: #fff
}

.bg-gradient-dark.btn.active, .bg-gradient-dark.btn:active, .bg-gradient-dark.btn:not(:disabled):not(.disabled).active, .bg-gradient-dark.btn:not(:disabled):not(.disabled):active {
  background: #1d2124 linear-gradient(180deg, #3f4245, #1d2124) repeat-x !important
}

.bg-gradient-navy {
  color: #fff
}

.bg-gradient-navy {
  background: #001f3f linear-gradient(180deg, #26415c, #001f3f) repeat-x !important
}

.bg-gradient-navy.btn.disabled, .bg-gradient-navy.btn:disabled, .bg-gradient-navy.btn:not(:disabled):not(.disabled).active, .bg-gradient-navy.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-navy.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-navy.btn:hover {
  border-color: #00060c;
  color: #ececec
}

.bg-gradient-navy.btn:hover {
  background: #000c19 linear-gradient(180deg, #26313b, #000c19) repeat-x !important
}

.bg-gradient-navy.btn.active, .bg-gradient-navy.btn:active, .bg-gradient-navy.btn:not(:disabled):not(.disabled).active, .bg-gradient-navy.btn:not(:disabled):not(.disabled):active {
  border-color: #000;
  color: #fff
}

.bg-gradient-navy.btn.active, .bg-gradient-navy.btn:active, .bg-gradient-navy.btn:not(:disabled):not(.disabled).active, .bg-gradient-navy.btn:not(:disabled):not(.disabled):active {
  background: #00060c linear-gradient(180deg, #262b30, #00060c) repeat-x !important
}

.bg-gradient-olive {
  color: #fff
}

.bg-gradient-olive {
  background: #3d9970 linear-gradient(180deg, #5aa885, #3d9970) repeat-x !important
}

.bg-gradient-olive.btn.disabled, .bg-gradient-olive.btn:disabled, .bg-gradient-olive.btn:not(:disabled):not(.disabled).active, .bg-gradient-olive.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-olive.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-olive.btn:hover {
  border-color: #2e7555;
  color: #ececec
}

.bg-gradient-olive.btn:hover {
  background: #327e5c linear-gradient(180deg, #519174, #327e5c) repeat-x !important
}

.bg-gradient-olive.btn.active, .bg-gradient-olive.btn:active, .bg-gradient-olive.btn:not(:disabled):not(.disabled).active, .bg-gradient-olive.btn:not(:disabled):not(.disabled):active {
  border-color: #2b6b4f;
  color: #fff
}

.bg-gradient-olive.btn.active, .bg-gradient-olive.btn:active, .bg-gradient-olive.btn:not(:disabled):not(.disabled).active, .bg-gradient-olive.btn:not(:disabled):not(.disabled):active {
  background: #2e7555 linear-gradient(180deg, #4e896f, #2e7555) repeat-x !important
}

.bg-gradient-lime {
  color: #1f2d3d
}

.bg-gradient-lime {
  background: #01ff70 linear-gradient(180deg, #27ff85, #01ff70) repeat-x !important
}

.bg-gradient-lime.btn.disabled, .bg-gradient-lime.btn:disabled, .bg-gradient-lime.btn:not(:disabled):not(.disabled).active, .bg-gradient-lime.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-lime.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-lime.btn:hover {
  border-color: #00cd5a;
  color: #121a24
}

.bg-gradient-lime.btn:hover {
  background: #00da5f linear-gradient(180deg, #26df77, #00da5f) repeat-x !important
}

.bg-gradient-lime.btn.active, .bg-gradient-lime.btn:active, .bg-gradient-lime.btn:not(:disabled):not(.disabled).active, .bg-gradient-lime.btn:not(:disabled):not(.disabled):active {
  border-color: #00c054;
  color: #fff
}

.bg-gradient-lime.btn.active, .bg-gradient-lime.btn:active, .bg-gradient-lime.btn:not(:disabled):not(.disabled).active, .bg-gradient-lime.btn:not(:disabled):not(.disabled):active {
  background: #00cd5a linear-gradient(180deg, #26d572, #00cd5a) repeat-x !important
}

.bg-gradient-fuchsia {
  color: #fff
}

.bg-gradient-fuchsia {
  background: #f012be linear-gradient(180deg, #f236c8, #f012be) repeat-x !important
}

.bg-gradient-fuchsia.btn.disabled, .bg-gradient-fuchsia.btn:disabled, .bg-gradient-fuchsia.btn:not(:disabled):not(.disabled).active, .bg-gradient-fuchsia.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-fuchsia.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-fuchsia.btn:hover {
  border-color: #c30c9a;
  color: #ececec
}

.bg-gradient-fuchsia.btn:hover {
  background: #cf0da3 linear-gradient(180deg, #d631b1, #cf0da3) repeat-x !important
}

.bg-gradient-fuchsia.btn.active, .bg-gradient-fuchsia.btn:active, .bg-gradient-fuchsia.btn:not(:disabled):not(.disabled).active, .bg-gradient-fuchsia.btn:not(:disabled):not(.disabled):active {
  border-color: #b70c90;
  color: #fff
}

.bg-gradient-fuchsia.btn.active, .bg-gradient-fuchsia.btn:active, .bg-gradient-fuchsia.btn:not(:disabled):not(.disabled).active, .bg-gradient-fuchsia.btn:not(:disabled):not(.disabled):active {
  background: #c30c9a linear-gradient(180deg, #cc31a9, #c30c9a) repeat-x !important
}

.bg-gradient-maroon {
  color: #fff
}

.bg-gradient-maroon {
  background: #d81b60 linear-gradient(180deg, #de3d78, #d81b60) repeat-x !important
}

.bg-gradient-maroon.btn.disabled, .bg-gradient-maroon.btn:disabled, .bg-gradient-maroon.btn:not(:disabled):not(.disabled).active, .bg-gradient-maroon.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-maroon.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-maroon.btn:hover {
  border-color: #ab154c;
  color: #ececec
}

.bg-gradient-maroon.btn:hover {
  background: #b61751 linear-gradient(180deg, #c13a6b, #b61751) repeat-x !important
}

.bg-gradient-maroon.btn.active, .bg-gradient-maroon.btn:active, .bg-gradient-maroon.btn:not(:disabled):not(.disabled).active, .bg-gradient-maroon.btn:not(:disabled):not(.disabled):active {
  border-color: #9f1447;
  color: #fff
}

.bg-gradient-maroon.btn.active, .bg-gradient-maroon.btn:active, .bg-gradient-maroon.btn:not(:disabled):not(.disabled).active, .bg-gradient-maroon.btn:not(:disabled):not(.disabled):active {
  background: #ab154c linear-gradient(180deg, #b73867, #ab154c) repeat-x !important
}

.bg-gradient-blue {
  color: #fff
}

.bg-gradient-blue {
  background: #007bff linear-gradient(180deg, #268fff, #007bff) repeat-x !important
}

.bg-gradient-blue.btn.disabled, .bg-gradient-blue.btn:disabled, .bg-gradient-blue.btn:not(:disabled):not(.disabled).active, .bg-gradient-blue.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-blue.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-blue.btn:hover {
  border-color: #0062cc;
  color: #ececec
}

.bg-gradient-blue.btn:hover {
  background: #0069d9 linear-gradient(180deg, #267fde, #0069d9) repeat-x !important
}

.bg-gradient-blue.btn.active, .bg-gradient-blue.btn:active, .bg-gradient-blue.btn:not(:disabled):not(.disabled).active, .bg-gradient-blue.btn:not(:disabled):not(.disabled):active {
  border-color: #005cbf;
  color: #fff
}

.bg-gradient-blue.btn.active, .bg-gradient-blue.btn:active, .bg-gradient-blue.btn:not(:disabled):not(.disabled).active, .bg-gradient-blue.btn:not(:disabled):not(.disabled):active {
  background: #0062cc linear-gradient(180deg, #267ad4, #0062cc) repeat-x !important
}

.bg-gradient-indigo {
  color: #fff
}

.bg-gradient-indigo {
  background: #6610f2 linear-gradient(180deg, #7d34f4, #6610f2) repeat-x !important
}

.bg-gradient-indigo.btn.disabled, .bg-gradient-indigo.btn:disabled, .bg-gradient-indigo.btn:not(:disabled):not(.disabled).active, .bg-gradient-indigo.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-indigo.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-indigo.btn:hover {
  border-color: #510bc4;
  color: #ececec
}

.bg-gradient-indigo.btn:hover {
  background: #560bd0 linear-gradient(180deg, #7030d7, #560bd0) repeat-x !important
}

.bg-gradient-indigo.btn.active, .bg-gradient-indigo.btn:active, .bg-gradient-indigo.btn:not(:disabled):not(.disabled).active, .bg-gradient-indigo.btn:not(:disabled):not(.disabled):active {
  border-color: #4c0ab8;
  color: #fff
}

.bg-gradient-indigo.btn.active, .bg-gradient-indigo.btn:active, .bg-gradient-indigo.btn:not(:disabled):not(.disabled).active, .bg-gradient-indigo.btn:not(:disabled):not(.disabled):active {
  background: #510bc4 linear-gradient(180deg, #6b2fcd, #510bc4) repeat-x !important
}

.bg-gradient-purple {
  color: #fff
}

.bg-gradient-purple {
  background: #6f42c1 linear-gradient(180deg, #855eca, #6f42c1) repeat-x !important
}

.bg-gradient-purple.btn.disabled, .bg-gradient-purple.btn:disabled, .bg-gradient-purple.btn:not(:disabled):not(.disabled).active, .bg-gradient-purple.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-purple.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-purple.btn:hover {
  border-color: #59339d;
  color: #ececec
}

.bg-gradient-purple.btn:hover {
  background: #5e37a6 linear-gradient(180deg, #7655b4, #5e37a6) repeat-x !important
}

.bg-gradient-purple.btn.active, .bg-gradient-purple.btn:active, .bg-gradient-purple.btn:not(:disabled):not(.disabled).active, .bg-gradient-purple.btn:not(:disabled):not(.disabled):active {
  border-color: #533093;
  color: #fff
}

.bg-gradient-purple.btn.active, .bg-gradient-purple.btn:active, .bg-gradient-purple.btn:not(:disabled):not(.disabled).active, .bg-gradient-purple.btn:not(:disabled):not(.disabled):active {
  background: #59339d linear-gradient(180deg, #7252ab, #59339d) repeat-x !important
}

.bg-gradient-pink {
  color: #fff
}

.bg-gradient-pink {
  background: #e83e8c linear-gradient(180deg, #eb5b9d, #e83e8c) repeat-x !important
}

.bg-gradient-pink.btn.disabled, .bg-gradient-pink.btn:disabled, .bg-gradient-pink.btn:not(:disabled):not(.disabled).active, .bg-gradient-pink.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-pink.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-pink.btn:hover {
  border-color: #d91a72;
  color: #ececec
}

.bg-gradient-pink.btn:hover {
  background: #e41c78 linear-gradient(180deg, #e83e8c, #e41c78) repeat-x !important
}

.bg-gradient-pink.btn.active, .bg-gradient-pink.btn:active, .bg-gradient-pink.btn:not(:disabled):not(.disabled).active, .bg-gradient-pink.btn:not(:disabled):not(.disabled):active {
  border-color: #ce196c;
  color: #fff
}

.bg-gradient-pink.btn.active, .bg-gradient-pink.btn:active, .bg-gradient-pink.btn:not(:disabled):not(.disabled).active, .bg-gradient-pink.btn:not(:disabled):not(.disabled):active {
  background: #d91a72 linear-gradient(180deg, #df3c87, #d91a72) repeat-x !important
}

.bg-gradient-red {
  color: #fff
}

.bg-gradient-red {
  background: #dc3545 linear-gradient(180deg, #e15361, #dc3545) repeat-x !important
}

.bg-gradient-red.btn.disabled, .bg-gradient-red.btn:disabled, .bg-gradient-red.btn:not(:disabled):not(.disabled).active, .bg-gradient-red.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-red.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-red.btn:hover {
  border-color: #bd2130;
  color: #ececec
}

.bg-gradient-red.btn:hover {
  background: #c82333 linear-gradient(180deg, #d04451, #c82333) repeat-x !important
}

.bg-gradient-red.btn.active, .bg-gradient-red.btn:active, .bg-gradient-red.btn:not(:disabled):not(.disabled).active, .bg-gradient-red.btn:not(:disabled):not(.disabled):active {
  border-color: #b21f2d;
  color: #fff
}

.bg-gradient-red.btn.active, .bg-gradient-red.btn:active, .bg-gradient-red.btn:not(:disabled):not(.disabled).active, .bg-gradient-red.btn:not(:disabled):not(.disabled):active {
  background: #bd2130 linear-gradient(180deg, #c7424f, #bd2130) repeat-x !important
}

.bg-gradient-orange {
  color: #1f2d3d
}

.bg-gradient-orange {
  background: #fd7e14 linear-gradient(180deg, #fd9137, #fd7e14) repeat-x !important
}

.bg-gradient-orange.btn.disabled, .bg-gradient-orange.btn:disabled, .bg-gradient-orange.btn:not(:disabled):not(.disabled).active, .bg-gradient-orange.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-orange.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-orange.btn:hover {
  border-color: #dc6502;
  color: #121a24
}

.bg-gradient-orange.btn:hover {
  background: #e96b02 linear-gradient(180deg, #ec8128, #e96b02) repeat-x !important
}

.bg-gradient-orange.btn.active, .bg-gradient-orange.btn:active, .bg-gradient-orange.btn:not(:disabled):not(.disabled).active, .bg-gradient-orange.btn:not(:disabled):not(.disabled):active {
  border-color: #cf5f02;
  color: #fff
}

.bg-gradient-orange.btn.active, .bg-gradient-orange.btn:active, .bg-gradient-orange.btn:not(:disabled):not(.disabled).active, .bg-gradient-orange.btn:not(:disabled):not(.disabled):active {
  background: #dc6502 linear-gradient(180deg, #e17c28, #dc6502) repeat-x !important
}

.bg-gradient-yellow {
  color: #1f2d3d
}

.bg-gradient-yellow {
  background: #ffc107 linear-gradient(180deg, #ffca2c, #ffc107) repeat-x !important
}

.bg-gradient-yellow.btn.disabled, .bg-gradient-yellow.btn:disabled, .bg-gradient-yellow.btn:not(:disabled):not(.disabled).active, .bg-gradient-yellow.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-yellow.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-yellow.btn:hover {
  border-color: #d39e00;
  color: #121a24
}

.bg-gradient-yellow.btn:hover {
  background: #e0a800 linear-gradient(180deg, #e4b526, #e0a800) repeat-x !important
}

.bg-gradient-yellow.btn.active, .bg-gradient-yellow.btn:active, .bg-gradient-yellow.btn:not(:disabled):not(.disabled).active, .bg-gradient-yellow.btn:not(:disabled):not(.disabled):active {
  border-color: #c69500;
  color: #1f2d3d
}

.bg-gradient-yellow.btn.active, .bg-gradient-yellow.btn:active, .bg-gradient-yellow.btn:not(:disabled):not(.disabled).active, .bg-gradient-yellow.btn:not(:disabled):not(.disabled):active {
  background: #d39e00 linear-gradient(180deg, #daad26, #d39e00) repeat-x !important
}

.bg-gradient-green {
  color: #fff
}

.bg-gradient-green {
  background: #28a745 linear-gradient(180deg, #48b461, #28a745) repeat-x !important
}

.bg-gradient-green.btn.disabled, .bg-gradient-green.btn:disabled, .bg-gradient-green.btn:not(:disabled):not(.disabled).active, .bg-gradient-green.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-green.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-green.btn:hover {
  border-color: #1e7e34;
  color: #ececec
}

.bg-gradient-green.btn:hover {
  background: #218838 linear-gradient(180deg, #429a56, #218838) repeat-x !important
}

.bg-gradient-green.btn.active, .bg-gradient-green.btn:active, .bg-gradient-green.btn:not(:disabled):not(.disabled).active, .bg-gradient-green.btn:not(:disabled):not(.disabled):active {
  border-color: #1c7430;
  color: #fff
}

.bg-gradient-green.btn.active, .bg-gradient-green.btn:active, .bg-gradient-green.btn:not(:disabled):not(.disabled).active, .bg-gradient-green.btn:not(:disabled):not(.disabled):active {
  background: #1e7e34 linear-gradient(180deg, #409152, #1e7e34) repeat-x !important
}

.bg-gradient-teal {
  color: #fff
}

.bg-gradient-teal {
  background: #20c997 linear-gradient(180deg, #41d1a7, #20c997) repeat-x !important
}

.bg-gradient-teal.btn.disabled, .bg-gradient-teal.btn:disabled, .bg-gradient-teal.btn:not(:disabled):not(.disabled).active, .bg-gradient-teal.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-teal.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-teal.btn:hover {
  border-color: #199d76;
  color: #ececec
}

.bg-gradient-teal.btn:hover {
  background: #1ba87e linear-gradient(180deg, #3db592, #1ba87e) repeat-x !important
}

.bg-gradient-teal.btn.active, .bg-gradient-teal.btn:active, .bg-gradient-teal.btn:not(:disabled):not(.disabled).active, .bg-gradient-teal.btn:not(:disabled):not(.disabled):active {
  border-color: #17926e;
  color: #fff
}

.bg-gradient-teal.btn.active, .bg-gradient-teal.btn:active, .bg-gradient-teal.btn:not(:disabled):not(.disabled).active, .bg-gradient-teal.btn:not(:disabled):not(.disabled):active {
  background: #199d76 linear-gradient(180deg, #3bac8b, #199d76) repeat-x !important
}

.bg-gradient-cyan {
  color: #fff
}

.bg-gradient-cyan {
  background: #17a2b8 linear-gradient(180deg, #3ab0c3, #17a2b8) repeat-x !important
}

.bg-gradient-cyan.btn.disabled, .bg-gradient-cyan.btn:disabled, .bg-gradient-cyan.btn:not(:disabled):not(.disabled).active, .bg-gradient-cyan.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-cyan.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-cyan.btn:hover {
  border-color: #117a8b;
  color: #ececec
}

.bg-gradient-cyan.btn:hover {
  background: #138496 linear-gradient(180deg, #3697a6, #138496) repeat-x !important
}

.bg-gradient-cyan.btn.active, .bg-gradient-cyan.btn:active, .bg-gradient-cyan.btn:not(:disabled):not(.disabled).active, .bg-gradient-cyan.btn:not(:disabled):not(.disabled):active {
  border-color: #10707f;
  color: #fff
}

.bg-gradient-cyan.btn.active, .bg-gradient-cyan.btn:active, .bg-gradient-cyan.btn:not(:disabled):not(.disabled).active, .bg-gradient-cyan.btn:not(:disabled):not(.disabled):active {
  background: #117a8b linear-gradient(180deg, #358e9c, #117a8b) repeat-x !important
}

.bg-gradient-white {
  color: #1f2d3d
}

.bg-gradient-white {
  background: #fff linear-gradient(180deg, #fff, #fff) repeat-x !important
}

.bg-gradient-white.btn.disabled, .bg-gradient-white.btn:disabled, .bg-gradient-white.btn:not(:disabled):not(.disabled).active, .bg-gradient-white.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-white.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-white.btn:hover {
  border-color: #e6e6e6;
  color: #121a24
}

.bg-gradient-white.btn:hover {
  background: #ececec linear-gradient(180deg, #efefef, #ececec) repeat-x !important
}

.bg-gradient-white.btn.active, .bg-gradient-white.btn:active, .bg-gradient-white.btn:not(:disabled):not(.disabled).active, .bg-gradient-white.btn:not(:disabled):not(.disabled):active {
  border-color: #dfdfdf;
  color: #1f2d3d
}

.bg-gradient-white.btn.active, .bg-gradient-white.btn:active, .bg-gradient-white.btn:not(:disabled):not(.disabled).active, .bg-gradient-white.btn:not(:disabled):not(.disabled):active {
  background: #e6e6e6 linear-gradient(180deg, #e9e9e9, #e6e6e6) repeat-x !important
}

.bg-gradient-gray {
  color: #fff
}

.bg-gradient-gray {
  background: #6c757d linear-gradient(180deg, #828a91, #6c757d) repeat-x !important
}

.bg-gradient-gray.btn.disabled, .bg-gradient-gray.btn:disabled, .bg-gradient-gray.btn:not(:disabled):not(.disabled).active, .bg-gradient-gray.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-gray.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-gray.btn:hover {
  border-color: #545b62;
  color: #ececec
}

.bg-gradient-gray.btn:hover {
  background: #5a6268 linear-gradient(180deg, #73797f, #5a6268) repeat-x !important
}

.bg-gradient-gray.btn.active, .bg-gradient-gray.btn:active, .bg-gradient-gray.btn:not(:disabled):not(.disabled).active, .bg-gradient-gray.btn:not(:disabled):not(.disabled):active {
  border-color: #4e555b;
  color: #fff
}

.bg-gradient-gray.btn.active, .bg-gradient-gray.btn:active, .bg-gradient-gray.btn:not(:disabled):not(.disabled).active, .bg-gradient-gray.btn:not(:disabled):not(.disabled):active {
  background: #545b62 linear-gradient(180deg, #6e7479, #545b62) repeat-x !important
}

.bg-gradient-gray-dark {
  color: #fff
}

.bg-gradient-gray-dark {
  background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x !important
}

.bg-gradient-gray-dark.btn.disabled, .bg-gradient-gray-dark.btn:disabled, .bg-gradient-gray-dark.btn:not(:disabled):not(.disabled).active, .bg-gradient-gray-dark.btn:not(:disabled):not(.disabled):active, .show > .bg-gradient-gray-dark.btn.dropdown-toggle {
  background-image: none !important
}

.bg-gradient-gray-dark.btn:hover {
  border-color: #1d2124;
  color: #ececec
}

.bg-gradient-gray-dark.btn:hover {
  background: #23272b linear-gradient(180deg, #44474b, #23272b) repeat-x !important
}

.bg-gradient-gray-dark.btn.active, .bg-gradient-gray-dark.btn:active, .bg-gradient-gray-dark.btn:not(:disabled):not(.disabled).active, .bg-gradient-gray-dark.btn:not(:disabled):not(.disabled):active {
  border-color: #171a1d;
  color: #fff
}

.bg-gradient-gray-dark.btn.active, .bg-gradient-gray-dark.btn:active, .bg-gradient-gray-dark.btn:not(:disabled):not(.disabled).active, .bg-gradient-gray-dark.btn:not(:disabled):not(.disabled):active {
  background: #1d2124 linear-gradient(180deg, #3f4245, #1d2124) repeat-x !important
}

[class^=bg-].disabled {
  opacity: .65
}

a.text-muted:hover {
  color: #007bff !important
}

.link-muted {
  color: #5d6974
}

.link-muted:focus, .link-muted:hover {
  color: #464f58
}

.link-black {
  color: #6c757d
}

.link-black:focus, .link-black:hover {
  color: #e6e8ea
}

.accent-primary a {
  color: #007bff
}

.accent-primary a:hover {
  color: #0056b3
}

.accent-primary .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff
}

.accent-primary .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-secondary a {
  color: #6c757d
}

.accent-secondary a:hover {
  color: #494f54
}

.accent-secondary .page-item.active .page-link {
  background-color: #6c757d;
  border-color: #6c757d
}

.accent-secondary .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-success a {
  color: #28a745
}

.accent-success a:hover {
  color: #19692c
}

.accent-success .page-item.active .page-link {
  background-color: #28a745;
  border-color: #28a745
}

.accent-success .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-info a {
  color: #17a2b8
}

.accent-info a:hover {
  color: #0f6674
}

.accent-info .page-item.active .page-link {
  background-color: #17a2b8;
  border-color: #17a2b8
}

.accent-info .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-warning a {
  color: #ffc107
}

.accent-warning a:hover {
  color: #ba8b00
}

.accent-warning .page-item.active .page-link {
  background-color: #ffc107;
  border-color: #ffc107
}

.accent-warning .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-danger a {
  color: #dc3545
}

.accent-danger a:hover {
  color: #a71d2a
}

.accent-danger .page-item.active .page-link {
  background-color: #dc3545;
  border-color: #dc3545
}

.accent-danger .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-light a {
  color: #f8f9fa
}

.accent-light a:hover {
  color: #cbd3da
}

.accent-light .page-item.active .page-link {
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.accent-light .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-dark a {
  color: #343a40
}

.accent-dark a:hover {
  color: #121416
}

.accent-dark .page-item.active .page-link {
  background-color: #343a40;
  border-color: #343a40
}

.accent-dark .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-navy a {
  color: #001f3f
}

.accent-navy a:hover {
  color: #000
}

.accent-navy .page-item.active .page-link {
  background-color: #001f3f;
  border-color: #001f3f
}

.accent-navy .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-olive a {
  color: #3d9970
}

.accent-olive a:hover {
  color: #276248
}

.accent-olive .page-item.active .page-link {
  background-color: #3d9970;
  border-color: #3d9970
}

.accent-olive .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-lime a {
  color: #01ff70
}

.accent-lime a:hover {
  color: #00b44e
}

.accent-lime .page-item.active .page-link {
  background-color: #01ff70;
  border-color: #01ff70
}

.accent-lime .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-fuchsia a {
  color: #f012be
}

.accent-fuchsia a:hover {
  color: #ab0b87
}

.accent-fuchsia .page-item.active .page-link {
  background-color: #f012be;
  border-color: #f012be
}

.accent-fuchsia .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-maroon a {
  color: #d81b60
}

.accent-maroon a:hover {
  color: #941342
}

.accent-maroon .page-item.active .page-link {
  background-color: #d81b60;
  border-color: #d81b60
}

.accent-maroon .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-blue a {
  color: #007bff
}

.accent-blue a:hover {
  color: #0056b3
}

.accent-blue .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff
}

.accent-blue .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-indigo a {
  color: #6610f2
}

.accent-indigo a:hover {
  color: #4709ac
}

.accent-indigo .page-item.active .page-link {
  background-color: #6610f2;
  border-color: #6610f2
}

.accent-indigo .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-purple a {
  color: #6f42c1
}

.accent-purple a:hover {
  color: #4e2d89
}

.accent-purple .page-item.active .page-link {
  background-color: #6f42c1;
  border-color: #6f42c1
}

.accent-purple .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-pink a {
  color: #e83e8c
}

.accent-pink a:hover {
  color: #c21766
}

.accent-pink .page-item.active .page-link {
  background-color: #e83e8c;
  border-color: #e83e8c
}

.accent-pink .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-red a {
  color: #dc3545
}

.accent-red a:hover {
  color: #a71d2a
}

.accent-red .page-item.active .page-link {
  background-color: #dc3545;
  border-color: #dc3545
}

.accent-red .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-orange a {
  color: #fd7e14
}

.accent-orange a:hover {
  color: #c35a02
}

.accent-orange .page-item.active .page-link {
  background-color: #fd7e14;
  border-color: #fd7e14
}

.accent-orange .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-yellow a {
  color: #ffc107
}

.accent-yellow a:hover {
  color: #ba8b00
}

.accent-yellow .page-item.active .page-link {
  background-color: #ffc107;
  border-color: #ffc107
}

.accent-yellow .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-green a {
  color: #28a745
}

.accent-green a:hover {
  color: #19692c
}

.accent-green .page-item.active .page-link {
  background-color: #28a745;
  border-color: #28a745
}

.accent-green .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-teal a {
  color: #20c997
}

.accent-teal a:hover {
  color: #158765
}

.accent-teal .page-item.active .page-link {
  background-color: #20c997;
  border-color: #20c997
}

.accent-teal .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-cyan a {
  color: #17a2b8
}

.accent-cyan a:hover {
  color: #0f6674
}

.accent-cyan .page-item.active .page-link {
  background-color: #17a2b8;
  border-color: #17a2b8
}

.accent-cyan .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-white a {
  color: #fff
}

.accent-white a:hover {
  color: #d9d9d9
}

.accent-white .page-item.active .page-link {
  background-color: #fff;
  border-color: #fff
}

.accent-white .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-gray a {
  color: #6c757d
}

.accent-gray a:hover {
  color: #494f54
}

.accent-gray .page-item.active .page-link {
  background-color: #6c757d;
  border-color: #6c757d
}

.accent-gray .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}

.accent-gray-dark a {
  color: #343a40
}

.accent-gray-dark a:hover {
  color: #121416
}

.accent-gray-dark .page-item.active .page-link {
  background-color: #343a40;
  border-color: #343a40
}

.accent-gray-dark .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6
}
.m-portlet {
  box-shadow: 0px 1px 7px 1px rgb(69 65 78 / 21%) !important;
  background-color: #fff !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
  //border: #000 solid 2px;
}

.input-sale .select2-container--default .select2-selection--multiple, .input-sale .select2-container--default .select2-selection--single {
  border: 1px solid #0066FF !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}
.input-sale .select2-container--default .select2-selection--multiple .select2-selection__placeholder, .input-sale .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #0066FF !important;
}

ngx-daterangepicker-material .ranges li {
  padding: 0 !important;
}
ngx-daterangepicker-material .md-drppicker.drops-down-left.ltr.shown.double.show-ranges {
  //left: 1175px !important;
  left: 940px !important;
}

.md-drppicker .ranges ul li button.active {
  background-color: #0066FF !important;
}
.md-drppicker td.active, .md-drppicker td.active:hover {
  background-color: #0066FF !important;
}
.md-drppicker td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date {
  color: #fff !important;
}
.md-drppicker .btn, .md-drppicker .btn:focus, .md-drppicker .btn:hover {
  background-color: #0066FF !important;
}
